import axios from "axios";
import getHeaders from "./headers";
import { ViewControls } from "modules/session";
import { Limits } from "modules/api/auth-service/permissions";
import { RegionInfo } from "modules/region/config";

export const AUTHZ_API_URL = `${import.meta.env.VITE_AUTH_URL}/auth/identity`;

export type FeatureFlags = Record<string, boolean>;

export type UserIdentity = {
  view_controls: ViewControls;
  role: string;
  plan: string;
  planSynced: boolean;
  plan_display_name: string;
  plan_monthly_price: number;
  is_plan_deprecated: boolean;
  limits: Limits;
  id: string;
  feature_flags: FeatureFlags;
  userRegionGroups: Record<string, RegionInfo>;
  eligible_for_trial: boolean;
  otp_enabled: boolean;
  pending_email: string;
};

export const getUserIdentity = async () => {
  return await axios
    .get<UserIdentity>(AUTHZ_API_URL, {
      headers: getHeaders(),
    })
    .then(({ data }) => {
      return data;
    })
    .catch((e) => {
      throw e;
    });
};
