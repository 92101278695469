import { Region } from "modules/region";
import loadable from "@loadable/component";
import { Route, Outlet, Navigate } from "react-router-dom";
import {
  KEY_URI_KEY,
  FUNCTION_URI_KEY,
  COLLECTION_URI_KEY,
  ACCESS_PROVIDER_URI_KEY,
  ROLE_URI_KEY,
  DOCUMENT_URI_KEY,
  INDEX_URI_KEY,
  SCHEMA_URI_KEY,
} from "pages/Resources/Explorer/routing";
import { useServerFeatureFlag, FeatureFlag } from "modules/utils/featureFlags";

const defaultRegion = Region?.default?.regionPrefix ?? "us";

const ExplorerContextProvider = loadable(
  () => import("pages/Resources/Explorer/routing/ExplorerContextProvider")
);
const ExplorerLayout = loadable(
  () => import("pages/Resources/Explorer/Layouts/ExplorerLayout")
);
const ContentLayout = loadable(
  () => import("pages/Resources/Explorer/Layouts/ContentLayout")
);
const DatabaseResourceValidator = loadable(
  () => import("pages/Resources/Explorer/Validators/DatabaseResourceValidator")
);
const RegionLanding = loadable(
  () => import("pages/Resources/Explorer/Content/RegionLanding")
);
const DatabaseOverview = loadable(
  () => import("pages/Resources/Explorer/Content/DatabaseOverview")
);
const Collections = loadable(
  () => import("pages/Resources/Explorer/Content/Collections")
);
const CollectionOverview = loadable(
  () =>
    import("pages/Resources/Explorer/Content/Collections/CollectionOverview")
);
const DocumentContextProvider = loadable(
  () => import("pages/Resources/Explorer/routing/DocumentContextProvider")
);
const DocumentValidator = loadable(
  () => import("pages/Resources/Explorer/Validators/DocumentValidator")
);
const DocumentOverview = loadable(
  () =>
    import(
      "pages/Resources/Explorer/Content/Collections/CollectionOverview/Documents/DocumentOverview"
    )
);
const Functions = loadable(
  () => import("pages/Resources/Explorer/Content/Functions")
);
const FunctionOverview = loadable(
  () => import("pages/Resources/Explorer/Content/Functions/FunctionOverview")
);
const Roles = loadable(() => import("pages/Resources/Explorer/Content/Roles"));
const RoleContextProvider = loadable(
  () => import("pages/Resources/Explorer/routing/RoleContextProvider")
);
const RoleOverview = loadable(
  () => import("pages/Resources/Explorer/Content/Roles/RoleOverview")
);
const ManageRole = loadable(
  () => import("pages/Resources/Explorer/Content/Roles/RoleOverview/Manage")
);
const ManageRolePrivileges = loadable(
  () =>
    import(
      "pages/Resources/Explorer/Content/Roles/RoleOverview/Manage/Privileges"
    )
);
const ManageRoleMemberships = loadable(
  () =>
    import(
      "pages/Resources/Explorer/Content/Roles/RoleOverview/Manage/Memberships"
    )
);

const RolesPageWithFF = () => {
  // If the feature flag is enabled redirect to the new roles page,
  // otherwise render the old roles page
  const newRolesPageEnabled =
    useServerFeatureFlag(FeatureFlag.NEW_ROLES_PAGE) ||
    import.meta.env.VITE_NEW_ROLES_PAGE === "true";
  if (newRolesPageEnabled) return <Navigate to="manage" />;
  return <RoleOverview />;
};

/**
 * The routes for the explorer page. These view rely heavily on react
 * router outlets and outlet context to pass data down the component tree.
 * Data is first parsed from the URL via a context provider, then validated, and
 * finally passed to the application views.
 * @see https://reactrouter.com/en/main/components/outlet
 */
export default [
  <Route
    key="explorer-redirect"
    path="explorer"
    element={<Navigate to={defaultRegion} replace />}
  />,
  <Route
    key="explorer"
    path="explorer"
    element={
      <ExplorerContextProvider key={location.pathname}>
        <Outlet />
      </ExplorerContextProvider>
    }
  >
    <Route
      element={
        <ExplorerLayout>
          <Outlet />
        </ExplorerLayout>
      }
    >
      <Route
        element={
          <DatabaseResourceValidator>
            <Outlet />
          </DatabaseResourceValidator>
        }
      >
        <Route
          element={
            <ContentLayout>
              <Outlet />
            </ContentLayout>
          }
        >
          {/* Region landing page */}
          <Route path=":region" element={<RegionLanding />} />
          {/* Database overview page */}
          <Route
            path=":region/:db"
            element={<DatabaseOverview tab="shell" />}
          />
          <Route
            path={`:region/:db/${KEY_URI_KEY}`}
            element={<DatabaseOverview tab={KEY_URI_KEY} />}
          />
          <Route
            path={`:region/:db/${ACCESS_PROVIDER_URI_KEY}`}
            element={<DatabaseOverview tab={ACCESS_PROVIDER_URI_KEY} />}
          />
          <Route
            path={`:region/:db/${ACCESS_PROVIDER_URI_KEY}/:name`}
            element={<DatabaseOverview tab={ACCESS_PROVIDER_URI_KEY} />}
          />
          <Route
            path={`:region/:db/${ACCESS_PROVIDER_URI_KEY}/:name/${SCHEMA_URI_KEY}`}
            element={<DatabaseOverview tab={ACCESS_PROVIDER_URI_KEY} />}
          />
          {/* Collections page */}
          <Route
            path={`:region/:db/${COLLECTION_URI_KEY}`}
            element={<Collections />}
          />
          {/* Collection overview page */}
          <Route
            path={`:region/:db/${COLLECTION_URI_KEY}/:name`}
            element={<CollectionOverview tab={DOCUMENT_URI_KEY} />}
          />
          <Route
            path={`:region/:db/${COLLECTION_URI_KEY}/:name/${DOCUMENT_URI_KEY}`}
            element={<CollectionOverview tab={DOCUMENT_URI_KEY} />}
          ></Route>
          <Route
            path={`:region/:db/${COLLECTION_URI_KEY}/:name/${INDEX_URI_KEY}`}
            element={<CollectionOverview tab={INDEX_URI_KEY} />}
          />
          <Route
            path={`:region/:db/${COLLECTION_URI_KEY}/:name/${SCHEMA_URI_KEY}`}
            element={<CollectionOverview tab={SCHEMA_URI_KEY} />}
          />
          {/* Document page */}
          <Route
            path={`:region/:db/${COLLECTION_URI_KEY}/:name/${DOCUMENT_URI_KEY}/:documentId`}
            element={
              <DocumentContextProvider>
                <Outlet />
              </DocumentContextProvider>
            }
          >
            <Route
              element={
                <DocumentValidator>
                  <Outlet />
                </DocumentValidator>
              }
            >
              <Route index element={<DocumentOverview />} />
            </Route>
          </Route>
          {/* Functions page */}
          <Route
            path={`:region/:db/${FUNCTION_URI_KEY}`}
            element={<Functions />}
          />
          {/* Function overview page */}
          <Route
            path={`:region/:db/${FUNCTION_URI_KEY}/:name`}
            element={<FunctionOverview />}
          />
          <Route
            path={`:region/:db/${FUNCTION_URI_KEY}/:name/${SCHEMA_URI_KEY}`}
            element={<FunctionOverview />}
          />
          {/* Roles page */}
          <Route path={`:region/:db/${ROLE_URI_KEY}`} element={<Roles />} />
          {/* Role overview page */}
          <Route
            path={`:region/:db/${ROLE_URI_KEY}/:name`}
            element={<RolesPageWithFF />}
          />
          <Route
            path={`:region/:db/${ROLE_URI_KEY}/:name`}
            element={
              <RoleContextProvider>
                <Outlet />
              </RoleContextProvider>
            }
          >
            <Route path={SCHEMA_URI_KEY} element={<RoleOverview />} />
            <Route
              path="manage"
              element={
                <RoleOverview>
                  <Outlet />
                </RoleOverview>
              }
            >
              <Route
                element={
                  <ManageRole>
                    <Outlet />
                  </ManageRole>
                }
              >
                <Route index element={<Navigate to="privileges" />} />
                <Route
                  path="privileges/:resource?"
                  element={<ManageRolePrivileges />}
                />
                <Route path="memberships" element={<ManageRoleMemberships />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>,
];
