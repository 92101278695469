import axios from "axios";
import { removeSession } from "../../session/index";
import getHeaders from "./headers";

export const API_URL = `${import.meta.env.VITE_AUTH_URL}/logout`;

const logout = async () => {
  await axios
    .post(API_URL, {}, { headers: getHeaders() })
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      removeSession();
    });
};

export default logout;
