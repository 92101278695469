export type RegionInfo = {
  displayName: string;
  abbr: string;
  frontdoorPrefix: string;
  prefixAliases?: string[];
  rate: number;
};

/*
  The default region group is used as the secret to communicate with the
  auth-service.
*/
export const DEFAULT_REGION_PREFIX =
  import.meta.env.VITE_DEFAULT_REGION_GROUP ?? "global";
