import { Region } from "modules/region";
import { getSession } from "../../session/index";

const getHeaders = () => {
  const session = getSession();

  if (session?.secret !== undefined) {
    return {
      secret: session?.secret,
    };
  }

  const region = Region.default;

  return {
    secret: region?.key,
    region: region?.regionPrefix,
  };
};

export default getHeaders;
