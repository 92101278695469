import axios from "axios";
import type { Limits, Plan } from "modules/api/auth-service/permissions";
import {
  SetupIntent,
  PaymentMethod,
  ExpressCheckoutAddress,
} from "@stripe/stripe-js";
import getHeaders from "./headers";
import type { SessionRegion } from "modules/session";
import type { RegionInfo } from "modules/region/config";

export const INVOICE_NEEDS_ACTION_STATUSES = new Set([
  "Failed",
  "Retrying",
  "Collections",
]);
export const INVOICE_PAID_STATUSES = new Set(["Paid"]);

export type BillingInfo = {
  plans: {
    usage: Plan;
  };
  payment_method_id: string;
  company_name: string;
  billing_email: string;
  phone_number?: string;
  free_usage_amount: number;
  default_payment_method_id: string;
};

export type UpgradeResponse = BillingInfo & {
  regionGroups: Record<string, SessionRegion>;
  userRegionGroups: Record<string, RegionInfo>;
  limits: Limits;
};

export type BillingPreview = {
  start_period: string;
  end_period: string;
  total_amount: number;
  free_usage_amount: number;
  line_items: Array<{ description: string; amount: number }>;
  metric_amount: any;
};

export type StripeCustomer = {
  name: string;
  email: string;
  phone: string;
  default_payment_method: string;
  metadata: Record<string, string>;
};

export type StripePaymentFormAddress = {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
};

export type CustomerTaxMetadata = {
  Address_Line_1: string;
  Address_Line_2: string;
  Address_City: string;
  Address_State: string;
  Address_PostalCode: string;
  Address_Country: string;
};

export type PaymentMethodSuccessParams = {
  setupIntent?: SetupIntent;
  addressData?: StripePaymentFormAddress;
};

export type Invoice = {
  date: string;
  amount: number;
  id: string;
  payment_method: string;
  status: string;
  url: string;
};

export const BILLING_PREVIEW_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/billing`;
export const BILLING_INFO_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/billing`;
export const PAYMENT_SOURCES_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/billing/sources`;
export const PAYMENT_METHODS_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/billing/payment_methods`;
export const SETUP_INTENT_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/billing/setup_intent`;
export const STRIPE_CUSTOMER_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/billing/customer`;
export const INVOICES_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/billing/invoices`;
export const UPGRADE_API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/billing/upgrade`;

export const getBillingPreview = async (params: {
  mode: string;
  days?: number;
  period?: string;
}) => {
  return await axios
    .get<BillingPreview>(`${BILLING_PREVIEW_API_URL}`, {
      params,
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getBillingInfo = async () => {
  return await axios
    .get<BillingInfo>(BILLING_INFO_API_URL, {
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const updateBillingInfo = async (data: Partial<BillingInfo>) => {
  return axios
    .put<BillingInfo>(BILLING_INFO_API_URL, data, {
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getPaymentMethods = async () => {
  return axios
    .get<Array<PaymentMethod>>(PAYMENT_METHODS_API_URL, {
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const createSetupIntent = async ({
  captcha_token,
}: {
  captcha_token?: string;
}) => {
  return axios
    .post<SetupIntent>(
      SETUP_INTENT_API_URL,
      { captcha_token },
      {
        headers: getHeaders(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const updateStripeCustomer = async (data: Partial<StripeCustomer>) => {
  return axios
    .put<StripeCustomer>(STRIPE_CUSTOMER_API_URL, data, {
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const deletePaymentMethod = async (paymentMethodId: string) => {
  return axios
    .delete(`${PAYMENT_METHODS_API_URL}/${paymentMethodId}`, {
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getInvoices = async () => {
  return axios
    .get<Array<Invoice>>(INVOICES_API_URL, {
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const updatePlan = async ({
  plan,
}: {
  plan: Plan;
}): Promise<UpgradeResponse> => {
  return axios
    .post<UpgradeResponse>(
      UPGRADE_API_URL,
      { plans: { usage: plan } },
      {
        headers: getHeaders(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const customerTaxMetadataFromAddress = (
  addressData: ExpressCheckoutAddress
): CustomerTaxMetadata => {
  return {
    Address_Line_1: addressData.line1,
    Address_Line_2: addressData.line2 || "",
    Address_City: addressData.city,
    Address_State: addressData.state,
    Address_PostalCode: addressData.postal_code,
    Address_Country: addressData.country,
  };
};
