import useSWR from "swr";
import { Plan } from "../api/auth-service/permissions";
import { getBillingInfo } from "../api/auth-service/billing";

const FREE_USAGE_PLANS: Array<Plan | undefined> = [Plan.free];
const TEAM_USAGE_PLANS: Array<Plan | undefined> = [Plan.team, Plan.business];
export const UPGRADEABLE_USAGE_PLANS: Array<Plan | undefined> = [
  Plan.free,
  Plan.individual,
  Plan.team,
  Plan.business,
];

const useBillingInfo = () => {
  const key = "billing_info";

  const billingInfo = useSWR(key, getBillingInfo);

  const currentPlan = billingInfo.data?.plans.usage;

  const hasTeamAccess = TEAM_USAGE_PLANS.includes(currentPlan);
  const allowUpgrade = UPGRADEABLE_USAGE_PLANS.includes(currentPlan);
  const isFreePlan = FREE_USAGE_PLANS.includes(currentPlan);

  return {
    currentPlan,
    hasTeamAccess,
    allowUpgrade,
    isFreePlan,
    billingInfo,
  };
};

export default useBillingInfo;
