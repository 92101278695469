import cookies from "js-cookie";

// Target cookies set by responses from the auth service via set-cookie header
// Pattern to get the root domain is safe because we know the possible
// values for the auth service domain.
export const COOKIE_DOMAIN = (import.meta.env.VITE_AUTH_URL || "")
  .split(".")
  .slice(-2)
  .join(".");

export const get = (name: string): any => {
  return cookies.get(name) && JSON.parse(window.atob(cookies.get(name) || ""));
};

export const remove = (name: string): void => {
  // This cookie gets removed while maintaining the session. cookies.remove
  // isn't as reliable as forcing expiration.
  cookies.set(name, "", {
    domain: COOKIE_DOMAIN,
    path: "/",
    expires: new Date(),
  });
};

export const exists = (name: string): boolean => {
  return !!cookies.get(name);
};

const AuthSvcCookie = {
  get,
  remove,
  exists,
};

export default AuthSvcCookie;
