import axios from "axios";
import { Role } from "modules/api/auth-service/permissions";
import getHeaders from "./headers";

export type NewInvite = {
  email: string;
  first_name: string;
  last_name: string;
  role: Role;
};

export type Invite = {
  id: string;
  email: string;
  name: string;
  role: Role;
  send_by: string;
};

export const API_URL = `${
  import.meta.env.VITE_AUTH_URL
}/account/members/invites`;

export const sendInvite = async (data: NewInvite) => {
  const res = await axios.post<Invite>(API_URL, data, {
    headers: getHeaders(),
  });
  return res.data;
};

export const getInvites = async () => {
  const res = await axios.get<Invite[]>(API_URL, {
    headers: getHeaders(),
  });
  return res.data;
};

export const resendInvite = async (inviteId: string) => {
  const res = await axios.put<Invite>(`${API_URL}/${inviteId}`, null, {
    headers: getHeaders(),
  });
  return res.data;
};

export const cancelInvite = async (inviteId: string) => {
  const res = await axios.delete<Invite>(`${API_URL}/${inviteId}`, {
    headers: getHeaders(),
  });
  return res.data;
};

export const getInviteByToken = async (token: string) => {
  const res = await axios.get<Invite>(`${API_URL}/${token}`);
  return res.data;
};
