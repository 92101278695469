import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { hasLocalSession, getSession } from "modules/session";
import { datadogRum } from "@datadog/browser-rum-slim";
import useBillingInfo from "modules/hooks/useBillingInfo";
import useRoutePattern from "modules/hooks/useRoutePattern";
import { whoami } from "modules/api/frontdoor/auth";
import { Region } from "modules/region";
import { UserIdentityProvider } from "modules/context/UserIdentityContext";
import { useBrowserSupport } from "modules/utils/browser";
import { BrowserSupport } from "pages/Login/BrowserSupport";
import { FeatureFlag, useServerFeatureFlag } from "modules/utils/featureFlags";

const BLOCKED_ROUTE_KEY = "blocked-route";

export const saveBlockedRoute = (overwrite = false) => {
  if (!getBlockedRoute() || overwrite) {
    window.sessionStorage.setItem(BLOCKED_ROUTE_KEY, window.location.href);
  }
};

export const getBlockedRoute = () => {
  return window.sessionStorage.getItem(BLOCKED_ROUTE_KEY);
};

export const removeBlockedRoute = () => {
  window.sessionStorage.removeItem(BLOCKED_ROUTE_KEY);
};

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const routePattern = useRoutePattern();

  useEffect(() => {
    if (hasSession && routePattern) {
      datadogRum.startView({ name: routePattern });
    }
  }, [location.pathname]);

  const [hasSession, setHasSession] = useState(false);
  const { currentPlan } = useBillingInfo();
  const zendeskEnabled = useServerFeatureFlag(FeatureFlag.ZENDESK_WIDGET);

  const browserInfo = useBrowserSupport();

  const onValidSession = () => {
    setHasSession(true);
    datadogRum.startView(routePattern);
  };

  const onInvalidSession = () => {
    saveBlockedRoute();
    setHasSession(false);
    navigate({ pathname: "/login", search: `?invalid-session=true` });
  };

  const checkRemoteSession = async () => {
    try {
      await whoami(Region.default);
      onValidSession();
    } catch (e: any) {
      // Ensure each request sets isUnavailable independent of previous
      //  requests' results
      if (e?.response?.status === 401) onInvalidSession();
      else onValidSession();
    }
  };

  // Redirect to the blocked route if we have one
  useEffect(() => {
    const blockedRoute = getBlockedRoute();
    if (hasSession && blockedRoute) {
      removeBlockedRoute();
      window.open(blockedRoute, "_self");
    }
  }, [location, hasSession]);

  // Session check
  useEffect(() => {
    // Fail fast if we don't have a local session cookie.
    if (!hasLocalSession()) {
      onInvalidSession();
    } else {
      // Make sure the users session is valid.
      if (import.meta.env.VITE_IS_LOCAL != "true") {
        checkRemoteSession();
      } else {
        setHasSession(true);
      }
    }
  }, [location, navigate]);

  // Initialize datadog
  useEffect(() => {
    if (!hasSession) return;
    const session = getSession();

    if (
      hasSession &&
      session &&
      import.meta.env.VITE_DD_APPLICATION_ID &&
      import.meta.env.VITE_DD_CLIENT_TOKEN
    ) {
      datadogRum.setUser({
        id: session.user.id,
        role: session.user.role,
        accountId: session.account.account_id,
        planLevel: currentPlan,
      });
      datadogRum.setGlobalContextProperty("userId", session.user.id);
    }
  }, [hasSession, currentPlan]);

  // Conditionally show/hide zendesk chat widget
  useEffect(() => {
    // https://developer.zendesk.com/api-reference/widget/core
    // @ts-ignore
    if (window.zE) {
      if (zendeskEnabled) {
        // @ts-ignore
        window.zE("webWidget", "show");
      } else {
        // @ts-ignore
        window.zE("webWidget", "hide");
      }
    }
  }, [zendeskEnabled]);

  // Initialize pendo
  useEffect(() => {
    if (!hasSession) return;
    const session = getSession();
    // @ts-ignore
    const shouldInitialize = session && typeof pendo !== "undefined";

    if (shouldInitialize) {
      const { user, account, signUpMethod } = session;

      const pendoParams = {
        visitor: {
          id: user.id,
          signUpMethod,
          name: user.name,
          role: user.role,
          email: user.email,
          createdAt: user.created_at,
        },
        account: {
          id: account.account_id,
          planLevel: currentPlan,
          companyName: account.company_name,
        },
      };

      try {
        // @ts-ignore
        if (pendo.initialize) pendo.initialize(pendoParams);
      } catch (e) {
        // Datadog RUM will pick up these errors automatically after logging
        //  https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors
        console.error("Issue initializing pendo", e);
      }
    }
  }, [hasSession, currentPlan]);

  return browserInfo && !browserInfo?.isSupported ? (
    <BrowserSupport browserInfo={browserInfo} />
  ) : hasSession ? (
    <UserIdentityProvider> {children} </UserIdentityProvider>
  ) : (
    children
  );
};

export default ProtectedRoute;
