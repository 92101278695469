import { getSession } from "modules/session";
import { FeatureFlag } from "./featureFlags";

export function getOldDashboardUrl() {
  const session = getSession();
  if (session?.feature_flags?.[FeatureFlag.V10_LAUNCHED]) {
    return `${import.meta.env.VITE_OLD_DASHBOARD_URL.substring(
      0,
      8
    )}v4.${import.meta.env.VITE_OLD_DASHBOARD_URL.substring(8)}`;
  }
  return import.meta.env.VITE_OLD_DASHBOARD_URL;
}

export function getDocsUrl() {
  const session = getSession();
  if (session?.feature_flags?.[FeatureFlag.V10_LAUNCHED]) {
    return "https://docs.fauna.com/fauna/current";
  }
  return "https://fqlx-beta--fauna-docs.netlify.app/fqlx/beta";
}

export function getDriversUrl() {
  return `${getDocsUrl()}/drivers/supported`;
}

export const communityUrl = "https://community-invite.fauna.com/";

export const statusUrl = "https://status.fauna.com/";

export const supportUrl = "https://support.fauna.com/hc/en-us";

export const toolsUrl = "https://docs.fauna.com/fauna/current/tools/";
