/** The default page size to fetch per service call. */
const DEFAULT_PAGE_SIZE = import.meta.env.VITE_PAGE_SIZE
  ? Number(import.meta.env.VITE_PAGE_SIZE)
  : 100;

/** The default page size to display to the user. */
const DEFAULT_PAGE_DISPLAY_SIZE = import.meta.env.VITE_DISPLAY_PAGE_SIZE
  ? Number(import.meta.env.VITE_DISPLAY_PAGE_SIZE)
  : 10;

/** The default debounce interval used by inputs etc */
export const DEBOUNCE_INTERVAL = 500;

/** The maximum number of items count in a count service call. */
export const MAX_COUNT = 10_001;

/**
 * Page sizes to fetch and display.
 * client page size controls display and
 * and server page size controls fetch size.
 * Mix and match as desired.
 */
export const PAGE_SIZES = {
  DEFAULT: {
    serverPageSize: DEFAULT_PAGE_SIZE,
    clientPageSize: DEFAULT_PAGE_DISPLAY_SIZE,
  },
  SMALL: {
    serverPageSize: 50,
    clientPageSize: 5,
  },
  MEDIUM: {
    serverPageSize: 100,
    clientPageSize: 10,
  },
  LARGE: {
    serverPageSize: 1000,
    clientPageSize: 100,
  },
  SERVER_ONLY_SMALL: {
    serverPageSize: 50,
    clientPageSize: 50,
  },
  SERVER_ONLY_MEDIUM: {
    serverPageSize: 100,
    clientPageSize: 100,
  },
  SERVER_ONLY_LARGE: {
    serverPageSize: 1000,
    clientPageSize: 1000,
  },
};
