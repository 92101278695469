import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import "./FaunaUnavailableBanner.scss";
import useSWR from "swr";
import axios from "axios";
import SupportTeamLink from "components/links/SupportTeamLink";
import {
  Layout,
  Text,
  StatusIcon,
  Card,
  Link,
  NotificationModal,
  Grid,
  Heading,
  StatusBanner,
} from "frontend-components";

type StatusBannerContextType = {
  isUnavailable: boolean | undefined;
  setIsUnavailable: (arg: boolean) => void;
  error: any;
  isLoading: boolean;
  dashboardStatusCode?: Status;
  statusData?: StatusDisplayComponents;
  statusTooltipContent?: ReactElement;
};

const statusNameMap = {
  Dashboard: "dashboard",
  Billing: "billing",
  "Support Services": "support",
  "US Region Group": "us",
  "EU Region Group": "eu",
  "Global Region Group": "global",
};

type StatusDisplayComponents = {
  dashboard: FaunaStatusComponent;
  billing: FaunaStatusComponent;
  support: FaunaStatusComponent;
  us: FaunaStatusComponent;
  eu: FaunaStatusComponent;
  global: FaunaStatusComponent;
};

type FaunaStatusComponent = {
  id: string;
  name: keyof typeof statusNameMap;
  status: Status;
  created_at: string;
  start_date: string;
  updated_at: string;
};

type FaunaStatusResponse = {
  page: any;
  components: FaunaStatusComponent[];
};

type Status =
  | "operational"
  | "degraded_performance"
  | "partial_outage"
  | "major_outage"
  | "under_maintenance";

const statusTextMap = {
  operational: "No Issues",
  degraded_performance: "Degraded Performance",
  partial_outage: "Partial Outage",
  major_outage: "Major Outage",
  under_maintenance: "Under Maintenance",
};

export const useFaunaStatus = () => {
  const { data, error, isValidating, isLoading } = useSWR(
    "fauna-status",
    (): Promise<StatusDisplayComponents | undefined> => {
      return axios
        .get<FaunaStatusResponse>(
          "https://status.fauna.com/api/v2/components.json"
        )
        .then((result) => {
          const components = result?.data.components;
          const componentsByName = components.reduce((acc: any, component) => {
            if (component.name in statusNameMap) {
              acc[statusNameMap[component.name]] = component;
            }
            return acc;
          }, {} as any);
          return componentsByName;
        });
    },
    // Refresh status every minute
    { dedupingInterval: 60 * 1000 }
  );
  return {
    data,
    error,
    isLoading: isValidating || isLoading,
  };
};

const AllServicesStatus = ({
  componentStatus,
}: {
  componentStatus: StatusDisplayComponents;
}) => {
  const groupStatusItems = useMemo(() => {
    return Object.values(componentStatus).map((value) => {
      return (
        <Card
          width="full"
          shadow="none"
          padding="lg"
          customClasses={"row"}
          key={value.id}
        >
          <Layout flow="left-right" justifyContent="space-between">
            <Text size="xl" bold>
              {value.name}
            </Text>
            <div style={{ float: "right" }}>
              <Layout flow="left-right" gap="lg">
                <Text size="xl" semibold>
                  {statusTextMap[value.status]}
                </Text>
                <StatusIcon theme={value.status} display={"circle"} />
              </Layout>
            </div>
          </Layout>
        </Card>
      );
    });
  }, [componentStatus]);
  return (
    <Layout
      className={"service-status-grid"}
      width="min"
      flow="top-bottom"
      gap="md"
    >
      {groupStatusItems}
    </Layout>
  );
};

export const StatusBannerContext = createContext<StatusBannerContextType>({
  isUnavailable: undefined,
  setIsUnavailable: () => {},
  error: undefined,
  isLoading: false,
  dashboardStatusCode: undefined,
  statusTooltipContent: undefined,
});

export const StatusBannerContextProvider: React.FC<{ children: any }> = (
  props
) => {
  // isUnavailable acts as a 3 way switch. Undefined is default state on page load.
  //  If we get a 500 response from an api call, we manually flip the switch to true.
  //  If an api call returns 200, it can manually flip it back to false.
  //  On each page load it gets reset to undefined so an api call or status event
  //  can decide if its true or false.
  const [isUnavailable, setIsUnavailable] = useState<boolean | undefined>(
    undefined
  );
  const [dashboardStatusCode, setDashboardStatusCode] =
    useState<Status>("operational");
  const { data, error, isLoading } = useFaunaStatus();
  const location = useLocation();

  const dashboardStatusEvent = data?.dashboard.status !== "operational";

  const statusTooltipContent = useMemo(() => {
    return isUnavailable ? (
      <Layout flow="top-bottom" gap="lg">
        <Text size="md">
          The current state of the Dashboard is:{" "}
          {dashboardStatusCode.split("_").join(" ")}. Please refer to the{" "}
          <Link href="https://status.fauna.com/" external>
            status page
          </Link>{" "}
          for more info.
          {/* If users are experiencing issues logging in but there is no
        status update yet, provide direction to support */}
          {data?.dashboard.status === "operational" && (
            <Text size="md" wrapper="span">
              {" "}
              Otherwise{" "}
              <SupportTeamLink
                type="link"
                text="contact Support to report an issue."
              />
            </Text>
          )}
        </Text>
      </Layout>
    ) : isLoading ? (
      <Text size="md">Loading status...</Text>
    ) : error ? (
      <Text size="md">
        Unable to fetch service status. Please refer to the{" "}
        <Link href="https://status.fauna.com/" external>
          status page
        </Link>{" "}
        for more info
      </Text>
    ) : (
      <Text size="md">All Dashboard systems operational</Text>
    );
  }, [isUnavailable, dashboardStatusCode, isLoading]);

  // When navigating to a new page, check status page for dashboard status,
  //  else reset to operational and wait for an api response to flip isUnavailable
  useEffect(() => {
    if (data && dashboardStatusEvent) {
      setIsUnavailable(true);
      setDashboardStatusCode(data?.dashboard.status);
    } else {
      // Reset to default "undefined"
      setIsUnavailable(undefined);
    }
  }, [location, data]);

  // We may show the banner and status icon before a status update gets posted
  //  if one of the api calls returns 500s (login/register/whoami)
  useEffect(() => {
    // No status event, but an api response returned 500 and manually flipped the switch
    if (isUnavailable && !dashboardStatusEvent) {
      setDashboardStatusCode("degraded_performance");
    }
    // If isUnavailable switches back to default "undefined" and we still have no status
    //  incident, reset status to "operational"
    if (isUnavailable === undefined && !dashboardStatusEvent) {
      setDashboardStatusCode("operational");
    }
  }, [isUnavailable]);

  return (
    <StatusBannerContext.Provider
      value={{
        isUnavailable,
        setIsUnavailable,
        error,
        isLoading,
        dashboardStatusCode,
        statusData: data,
        statusTooltipContent,
      }}
    >
      {props.children}
    </StatusBannerContext.Provider>
  );
};

export const FaunaStatusIcon: React.FC<{
  statusModalOpen: any;
  toggleStatusModal: any;
}> = ({ statusModalOpen, toggleStatusModal }) => {
  const { statusTooltipContent, dashboardStatusCode, statusData } =
    useContext(StatusBannerContext);

  const headerComponent = (
    <Grid item size={true} className="header">
      <Heading margin="none">Fauna Status Summary</Heading>
      <Text size="lg" wrapper="span">
        <Link external href="https://status.fauna.com/">
          View details on the Status Page
        </Link>
      </Text>
    </Grid>
  );

  return (
    <>
      <StatusIcon
        theme={dashboardStatusCode}
        tooltipContent={statusTooltipContent}
      />
      {statusModalOpen && statusData && (
        <NotificationModal
          size="lg"
          open={statusModalOpen}
          onClose={toggleStatusModal}
          header={headerComponent}
          customClasses={"service-status-modal"}
        >
          <Grid item size={2}>
            <AllServicesStatus componentStatus={statusData} />
          </Grid>
        </NotificationModal>
      )}
    </>
  );
};

export const FaunaUnavailableBanner = ({
  width,
  alwaysDisplay = false,
}: {
  width: "min" | "full";
  alwaysDisplay?: boolean;
}) => {
  const { dashboardStatusCode, statusTooltipContent } =
    useContext(StatusBannerContext);

  return !alwaysDisplay && dashboardStatusCode === "operational" ? null : (
    <StatusBanner
      tooltipContent={statusTooltipContent}
      statusText={`Status: ${dashboardStatusCode?.split("_").join(" ")}`}
      theme={dashboardStatusCode}
      width={width}
    />
  );
};
