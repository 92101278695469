import { FC, useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logout from "modules/api/auth-service/logout";
import {
  PermissionDomain,
  PermissionLevel,
  Plan,
} from "modules/api/auth-service/permissions";
import { UserIdentityContext } from "modules/context/UserIdentityContext";
import usePermissions from "modules/hooks/usePermissions";
import { UPGRADEABLE_USAGE_PLANS } from "modules/hooks/useBillingInfo";
import { tx } from "modules/language/translate";

import {
  communityUrl,
  getDocsUrl,
  getDriversUrl,
  statusUrl,
  supportUrl,
  toolsUrl,
} from "modules/utils/urls";
import { FaunaUnavailableBanner } from "modules/context/StatusBannerContext";
import { useAccountNotifications } from "modules/hooks/UseAccountNotifications";
import { useNotificationStore } from "modules/stores/NotificationStore";

import {
  Text,
  Icon,
  Menu,
  Button,
  Navbar,
  NavbarItem,
  NavbarLogo,
  NavbarGroup,
} from "frontend-components";

const FaunaNavbar: FC<any> = () => {
  const navigate = useNavigate();
  const currentIdentity = useContext(UserIdentityContext);
  const { hasPermission: hasUpgradePermission } = usePermissions(
    PermissionDomain.UPGRADES,
    PermissionLevel.create
  );

  const { hasPermission: hasBillingPermission } = usePermissions(
    PermissionDomain.BILLING,
    PermissionLevel.write
  );

  const [showNotification, setShowNotification] = useState(false);

  useAccountNotifications();
  const notification = useNotificationStore((state) => state.notification);

  useEffect(() => {
    // Metavy is required to meet with Fauna to discuss delinquent bills before seeing notifications.
    setShowNotification(notification !== undefined && hasBillingPermission);
  }, [notification, hasBillingPermission]);

  const allowUpgrade =
    hasUpgradePermission ||
    UPGRADEABLE_USAGE_PLANS.includes(currentIdentity.plan as Plan);

  const handleLogout = async () => {
    navigate("/login");

    await logout().catch((e) => {
      throw e;
    });
  };

  const docsSettingsOptions = [
    <a
      href={getDriversUrl()}
      target="_blank"
      className="menu-option"
      rel="noreferrer"
    >
      <Text wrapper="span">{tx("attributes.drivers")}</Text>
    </a>,
    <a
      href={getDocsUrl()}
      target="_blank"
      className="menu-option"
      rel="noreferrer"
    >
      <Text wrapper="span">{tx("attributes.documentation")}</Text>
    </a>,
    <a
      href={communityUrl}
      target="_blank"
      className="menu-option"
      rel="noreferrer"
    >
      <Text wrapper="span">{tx("attributes.community")}</Text>
    </a>,
    <a href={toolsUrl} target="_blank" className="menu-option" rel="noreferrer">
      <Text wrapper="span">{tx("attributes.tools")}</Text>
    </a>,
    <a
      href={supportUrl}
      target="_blank"
      className="menu-option"
      rel="noreferrer"
    >
      <Text wrapper="span">{tx("attributes.support")}</Text>
    </a>,
    <a
      href={statusUrl}
      target="_blank"
      className="menu-option"
      rel="noreferrer"
    >
      <Text wrapper="span">{tx("attributes.status")}</Text>
    </a>,
  ];
  const docsControl = (
    <Icon iconClassName="fa-book" hoverable={true} theme="secondary" />
  );

  const profileSettingsOptions = [
    <Link to="../resources/personal-settings" className="menu-option">
      <Icon iconClassName="fa-user-gear" theme="inherit" wrapper="span" />
      <Text wrapper="span">{tx("actions.personalSettings")}</Text>
    </Link>,
    <span className="menu-option" onClick={handleLogout}>
      <Icon
        wrapper="span"
        theme="inherit"
        iconClassName="fa-right-from-bracket"
      />
      <Text wrapper="span">{tx("auth.actions.sign_out")}</Text>
    </span>,
  ];

  const profileControl = (
    <Icon
      id="navbar-profile-icon"
      hoverable={true}
      theme="secondary"
      iconClassName="fa-user-circle"
    />
  );

  let leftItems = [
    <NavbarItem margin="lg" type="left-item">
      <Link to="/">
        <NavbarLogo />
      </Link>
    </NavbarItem>,
    <NavbarItem margin="sm" type="left-item">
      <Text size="xxl" theme="secondary">
        <Link to="/">Fauna</Link>
      </Text>
    </NavbarItem>,
  ];

  let rightItems = undefined;

  if (showNotification) {
    leftItems.push(
      <NavbarItem
        id="navbar-notification"
        type="left-item"
        margin="sm"
        width="full"
        style={{ width: "80%" }}
      >
        {notification?.asComponent()}
      </NavbarItem>,
      ...(currentIdentity.plan
        ? [
            <NavbarItem type="left-item" margin="md">
              <FaunaUnavailableBanner width="min" />
            </NavbarItem>,
            <NavbarItem type="left-item" margin="md">
              <NavbarGroup
                items={[
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text theme="secondary" size="md">
                      {tx("billing.attributes.plan")}
                    </Text>
                    <Text size="lg">{currentIdentity.plan_display_name}</Text>
                  </div>,
                  ...(allowUpgrade
                    ? [
                        <Button
                          id="navbar-upgrade-button"
                          size="md"
                          onClick={() =>
                            navigate(`../resources/account-settings/billing`)
                          }
                        >
                          {tx("billing.actions.upgrade")}
                        </Button>,
                      ]
                    : []),
                ]}
              />
            </NavbarItem>,
          ]
        : []),
      <NavbarItem type="left-item" margin="sm">
        <Menu items={docsSettingsOptions} menuControl={docsControl} />
      </NavbarItem>,
      <NavbarItem type="left-item" margin="lg">
        <Menu items={profileSettingsOptions} menuControl={profileControl} />
      </NavbarItem>
    );
  } else {
    rightItems = [
      ...(currentIdentity.plan
        ? [
            <NavbarItem type="right-item" margin="md">
              <FaunaUnavailableBanner width="min" />
            </NavbarItem>,
            <NavbarItem type="right-item" margin="md">
              <NavbarGroup
                items={[
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text theme="secondary" size="md">
                      {tx("billing.attributes.plan")}
                    </Text>
                    <Text size="lg">{currentIdentity.plan_display_name}</Text>
                  </div>,
                  ...(allowUpgrade
                    ? [
                        <Button
                          id="navbar-upgrade-button"
                          size="md"
                          onClick={() =>
                            navigate(`../resources/account-settings/billing`)
                          }
                        >
                          {tx("billing.actions.upgrade")}
                        </Button>,
                      ]
                    : []),
                ]}
              />
            </NavbarItem>,
          ]
        : []),
      <NavbarItem type="right-item" margin="sm">
        <Menu items={docsSettingsOptions} menuControl={docsControl} />
      </NavbarItem>,
      <NavbarItem type="right-item" margin="lg">
        <Menu items={profileSettingsOptions} menuControl={profileControl} />
      </NavbarItem>,
    ];
  }
  return (
    <Navbar
      width={showNotification ? "full" : "auto"}
      leftItemWidths={showNotification ? ["auto", "auto", "full"] : undefined}
      leftItems={leftItems}
      rightItems={rightItems}
      renderEmptyItems={false}
    />
  );
};

export default FaunaNavbar;
