import { SessionRegion } from "../session";
import { type RegionInfo, DEFAULT_REGION_PREFIX } from "./config";
import { getSession } from "../session";

type RegionType = SessionRegion & Partial<RegionInfo>;

export type Regions = Record<string, Region>;

// This can be used when running locally in conjunction with the auth-service.
//  Auth-service will return region groups with the DB url set to the value given
//  from the docker container (e.g. db-us:8443) whereas we want the dashboard to look
//  at http://localhost:8443
const DEFAULT_DB_URL = import.meta.env.VITE_DB_URL;
const DEFAULT_GRAPHQL_URL = import.meta.env.VITE_GRAPHQL_URL;

export class Region implements RegionType {
  // The prefix used by the auth-service to represent this region group.
  readonly regionPrefix: string;

  // The region name returned from the auth service.
  readonly regionName: string;

  // A customer-readable name for this region group.
  readonly displayName: string;

  // A short, customer-readable symbol for this region group.
  readonly abbr: string;

  // The billing rate for this region group.
  readonly rate: number;

  // The prefix used by the frontdoor service to refer to this region group.
  readonly frontdoorPrefix: string;

  // The database url for this region.
  readonly url: string;

  // The graphQL url for this region.
  readonly graphqlUrl: string;

  // The database key for this region.
  readonly secret: string;

  // Gets the DB key part of the secret (i.e., without db path / role).
  get key() {
    return this.secret.split(":")[0];
  }

  // Gets a customer-readable label for use in form select boxes.
  get label() {
    return `${this.displayName} (${this.abbr})`;
  }

  // Looks up a region by its prefix or any of its prefix aliases.
  static get(prefix: string): Region | undefined {
    if (!Region.initialized) Region.init();
    return Region.regionsByPrefix.get(prefix);
  }

  // Gets the default region group.
  static get default(): Region {
    if (!Region.initialized) Region.init();
    return Region.get(DEFAULT_REGION_PREFIX) as Region;
  }

  // Returns all regions, organized by regionPrefix.
  static get all(): Regions {
    if (!Region.initialized) Region.init();
    return Region.allRegions;
  }

  static rebuildAllRegions(): void {
    Region.initialized = false;
    Region.init();
  }

  // Resets all region data -- use this at logout.
  static clear() {
    Region.initialized = false;
    Region.regionsByPrefix.clear();
    Region.allRegions = {};
  }

  // Applies a function to each region, and returns the results as an array.
  static map<T>(fn: (region: Region) => T) {
    return Object.values(Region.all).map(fn);
  }

  private constructor(
    prefix: string,
    sessionRegion: SessionRegion,
    regionMetadata?: RegionInfo
  ) {
    const info: RegionType = regionMetadata
      ? { ...regionMetadata, ...sessionRegion }
      : sessionRegion;
    this.regionPrefix = prefix;
    this.regionName = info.regionName;
    this.displayName = info.displayName ?? info.regionName;
    this.abbr = info.abbr ?? prefix.toUpperCase();
    this.rate = info.rate ?? 1;
    this.frontdoorPrefix = info.frontdoorPrefix ?? prefix;
    this.url =
      import.meta.env.VITE_IS_LOCAL == "true" && DEFAULT_DB_URL
        ? DEFAULT_DB_URL
        : info.url;
    this.graphqlUrl = info.graphqlUrl ?? DEFAULT_GRAPHQL_URL;
    this.secret = info.secret;

    // We need to be able to look up by prefix as well as by any aliases
    // (including frontdoorPrefix).
    Region.set(prefix, this);
    if (this.frontdoorPrefix !== prefix) Region.set(this.frontdoorPrefix, this);
    info.prefixAliases?.forEach((alias) => Region.set(alias, this));
    // For some reason the auth-service returns the prefix as part of the object
    // as well as the key. Make sure we remember both prefixes if they differ.
    if (info.regionPrefix && info.regionPrefix !== prefix)
      Region.set(info.regionPrefix, this);
  }

  private static allRegions: Record<string, Region> = {};
  private static regionsByPrefix = new Map<string, Region>();
  private static initialized = false;

  private static set(prefix: string, region: Region) {
    return this.regionsByPrefix.set(prefix, region);
  }

  // Initializes the regions map using the data in the SessionCookie. Does
  // nothing if the SessionCookie does not exist.
  private static init() {
    const session = getSession();
    if (!session) return;
    Region.clear();
    // userRegionGroups represents the region groups that the user has access to
    //  considering their plan and usage. regionGroups contains the secrets
    //  for each region group. We iterate through userRegionGroups to ensure that we
    //  use the set of region groups they have access to in the case where auth
    //  service fails to generate all required secrets for region groups in the
    //  regionGroups object.
    const { regionGroups, userRegionGroups } = session;
    Region.allRegions = Object.fromEntries(
      Object.entries(userRegionGroups).map(([prefix, regionGroupMetadata]) => [
        prefix,
        new Region(prefix, regionGroups[prefix], regionGroupMetadata),
      ])
    );
    Region.initialized = true;
  }
}

/*
  Example usage:
    import getRegion from '.../region'
    getRegion('global').frontdoorPrefix
*/
export default Region.get;
