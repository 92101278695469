import cookies from "js-cookie";

export type CookieConfig = {
  domain?: string;
  expires?: number | Date;
  secure?: boolean;
  sameSite?: "none" | "strict" | "Strict" | "lax" | "Lax" | "None";
  path?: string;
};

export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN ?? "";

export const get = (name: string): any => {
  return cookies.get(name) && JSON.parse(cookies.get(name) || "");
};

export const remove = (name: string, path = "/"): void => {
  cookies.remove(name, { domain: COOKIE_DOMAIN, path: path });
};

export const save = (
  name: string,
  contents: any,
  config: CookieConfig
): void => {
  cookies.set(name, JSON.stringify(contents), config);
};

export const exists = (name: string): boolean => {
  return !!cookies.get(name);
};
