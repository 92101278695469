import { Region } from "../../modules/region";
import { getOldDashboardUrl } from "./urls";

/**
 * Build a database name path string from a parentPath string and a databaseName
 * This assumes region group is not included in the parentPath.
 * @param parentNamePath the name path of the parent as a string
 * @param databaseName the name of the database
 * @param uriEncode true if to enocde the output as a URI false otherwise
 * @returns the name path
 */
export const buildDatabaseNamePath = (
  parentNamePath: string | undefined,
  databaseName: string,
  uriEncode = false
) => {
  const stripSlashes = /^\/+|\/+$/g;
  const cleanParentPath = parentNamePath?.replace(stripSlashes, "");
  const cleanDatabaseName = databaseName.replace(stripSlashes, "");
  const fullNamePath = cleanParentPath
    ? `${cleanParentPath}/${cleanDatabaseName}`
    : cleanDatabaseName;
  return uriEncode ? encodeURIComponent(fullNamePath) : fullNamePath;
};

/**
 * Encapsulates a database path
 */
export class DatabasePath {
  readonly namePath: string[];
  readonly regionGroup: Region;

  /**
   * constructs a DatabasePath from a path containing the region group.
   * @example 'us-std/foo/bar'
   */
  static fromString(path: string): DatabasePath {
    const [rg, ...namePath] = path.split("/");

    const regionGroup = Region.get(rg);
    if (!regionGroup) throw new Error(`Invalid region group '${rg}'`);

    return new DatabasePath(namePath, regionGroup);
  }

  /**
   * constructs a new DatabasePath
   * @param namePath - the path to the database (ignoring regionGroup). e.g. ["foo", "bar"]
   * @param regionGroup - the regionGroup the database is in
   */
  constructor(namePath: string[], regionGroup: Region) {
    this.namePath = namePath;
    this.regionGroup = regionGroup;
  }

  /**
   * @param databasePath
   * @return true if the input databasePath is in the tree rooted by this DatabasePath
   */
  isInDatabaseTree(databasePath: DatabasePath) {
    return databasePath.pathWithRegionGroupStr.startsWith(
      this.pathWithRegionGroupStr
    );
  }

  /**
   * Gets a child {@link DatabasePath} below this DatabasePath.
   * @param name - the name of the child database
   * @return the {@link DatabasePath} for the child of that name.
   */
  child(name: string) {
    return new DatabasePath([...this.namePath, name], this.regionGroup);
  }

  /**
   * @return true if this is a root of a region group; false otherwise.
   */
  get isRoot() {
    return this.namePath.length === 0;
  }

  /**
   * @return the parent {@link DatabasePath} of this DatabasePath. If you are at the root
   *   (new DatabasePath([], someRegion)) it will return a the root again.
   * @example
   * ```typescript
   *  new DatabasePath(["foo", "bar"], Region.global).parent();
   *  // returns new DatabasePath(["foo"], Region.global).parent();
   * ```
   */
  get parent() {
    return new DatabasePath(
      this.namePath.slice(0, this.namePath.length - 1),
      this.regionGroup
    );
  }

  /**
   * @param n the ancestor number
   * @return the nth ancestor {@link DatabasePath} of this DatabasePath. If you are at the root
   *   (new DatabasePath([], someRegion)) it will return a the root again.
   * @example
   * ```typescript
   *  new DatabasePath(["foo", "bar"], Region.global).parent();
   *  // returns new DatabasePath(["foo"], Region.global).parent();
   * ```
   */
  ancestor(n: number) {
    return new DatabasePath(
      this.namePath.slice(0, this.namePath.length - n),
      this.regionGroup
    );
  }

  /**
   * @return the path of the database, excluding the database name
   * @example if the name path ["foo", "bar", "baz"] this function returns "foo/bar"
   */
  get ancestorPathStr() {
    return this.namePath.slice(0, this.namePath.length - 1).join("/");
  }

  /**
   * @return the name of the database (excluding the ancestor path)
   * @example 'bar'
   */
  get name() {
    return this.namePath[this.namePath.length - 1];
  }

  /**
   * @return The name path as a string
   * @example 'foo/bar'

   */
  get namePathStr() {
    return this.namePath.join("/");
  }

  /**
   * @return The path including the region group as a string
   * @example 'us-std/foo/bar'
   */
  get pathWithRegionGroupStr() {
    return this.pathWithRegionGroupArr.join("/");
  }

  /**
   * @return The path including the display name for the region group as a string
   * @example 'United States/foo/bar'
   */
  get pathWithRegionGroupDisplayName() {
    return [this.regionGroup.displayName, ...this.namePath].join("/");
  }

  /**
   * @ return The path including the region group as an array
   * @example ['us-std', 'foo', 'bar']
   */
  get pathWithRegionGroupArr() {
    return [this.regionGroup.frontdoorPrefix, ...this.namePath];
  }

  get subpaths() {
    const rgPath = new DatabasePath([], this.regionGroup);
    return [
      rgPath,
      ...this.namePath.map((name, i) => {
        const namePath = [...this.namePath.slice(0, i), name];
        const path = new DatabasePath(namePath, this.regionGroup);
        return path;
      }),
    ];
  }

  get v4DBUrl() {
    return `${getOldDashboardUrl()}/db/${this.regionGroup.regionPrefix}/${
      this.namePathStr
    }`;
  }

  get v4WebshellUrl() {
    return `${getOldDashboardUrl()}/webshell/@db/${
      this.regionGroup.regionPrefix
    }/${this.namePathStr}`;
  }
}
